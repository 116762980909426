import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import '../scss/_404.scss';

function NotFoundPage() {
  return (
    <div>
      <Layout home isNotFound>
        <Container className="page-container">
          <div className="not-found-wrapper">
            <p className="error-code">404</p>
            <p className="title">Sayfa Bulunamadı</p>
            <p className="description">Lütfen ulaşmak istediğiniz adresi kontrol ederek tekrar deneyiniz.</p>
            <Link to="/">
              <Button className="go-home-button">Ana Sayfaya Geri Dön</Button>
            </Link>
          </div>
        </Container>
      </Layout>
    </div>
  );
}

export default NotFoundPage;
